export function getTooltipforOutput(values, list) {
  if (list === undefined || list === null || list.length === 0) return "";
  if (values.length === 0) {
    return list;
  }

  if (list[values] !== undefined) {
    return list[values];
  }

  const splitted = values.split("@#").map((part) => part.split(/[.[\]]+/).filter(Boolean)).flat();
  let result = list;
  for (let i = 0; i < splitted.length; i++) {
    if (typeof result === "object" && result !== null && !Array.isArray(list)) {
      if (splitted[i] in result) {
        result = result[splitted[i]];
      } else {
        const matches = splitted[i].match(/(\w+)\[(\d+)\]/);
        if (matches !== null && matches.length > 0) {
          for (let j = 0; j < matches.length; j++) {
            if (j !== 0) {
              result = result[matches[j]];
              if (result === undefined) {
                return "";
              }
            }
          }
        } else {
          result = "";
        }
      }
    } else {
      const matches = splitted[i].match(/(\w+)\[(\d+)\]/);
      if (matches !== null && matches.length > 0) {
        for (let j = 0; j < matches.length; j++) {
          if (j !== 0) {
            result = result[matches[j]];
            if (result === undefined) {
              return "";
            }
          }
        }
      } else {
        result = result[splitted[i]];
      }
    }
    if (result === undefined) return "";
  }
  return typeof result === "object" ? JSON.stringify(result) : result;
}

export function getTooltipForMetaoutput(value, index, metaoutputList) {
  const metaoutput = metaoutputList.find((item) => item.index === index);
  if (metaoutput !== undefined) {
    const selectedMetaoutput = JSON.parse(metaoutput.metaoutput);
    if (!Array.isArray(selectedMetaoutput)) {
      return selectedMetaoutput[value];
    }
  }
  return null;
}
function formatUdvList(userDefinedVariablesList) {
  const formattedObj = {};
  userDefinedVariablesList.forEach((item) => {
    let value = "";
    try {
      value = JSON.parse(item.value.itemValue);
    } catch (error) {
      value = item.value.itemValue;
    }
    formattedObj[item.value.itemKey] = value;
  });
  return formattedObj;
}
export function getTooltipForUdv(value, userDefinedVariablesList) {
  const pattern = /[^@#]+/g;
  const matches = value.match(pattern);
  let result = formatUdvList(userDefinedVariablesList);
  for (let i = 0; i < matches.length; i++) {
    if (matches[i] in result) {
      result = result[matches[i]];
    } else {
      result = null;
      break;
    }
  }
  return result;
}
