export function parseJSONWithStringNumbers(jsonString) {
  try {
    // Encapsulating numeric values inside double quotes just for UI representation.
    let modifiedJsonString = jsonString.replace(/(?<=:\s|,\s|^\s?)(-?\d+(\.\d+)?([eE][+-]?\d+)?)(?=[,}\]\s])/g, (match) => `"${match}"`);
    modifiedJsonString = modifiedJsonString.replace(/(?<=\[\s*)(-?\d+(\.\d+)?([eE][+-]?\d+)?)(?=[,\]\s])/g, (match) => `"${match}"`);
    modifiedJsonString = modifiedJsonString.replace(/(?<=,\s*)(-?\d+(\.\d+)?([eE][+-]?\d+)?)(?=[,\]\s])/g, (match) => `"${match}"`);

    JSON.parse(jsonString);
    return JSON.parse(modifiedJsonString);
  } catch (e) {
    console.warn("Invalid JSON format, attempting to parse as is:", jsonString);
    return JSON.parse(jsonString);
  }
}
